i<template>
    <span>
        {{response}}
    </span>
</template>

<script>
export default {
    name: "ResponseString",
    props: {
        id: {},
        value: {}
    }, 
    computed: {
        question(){
            return this.$store.state.questions.data[this.id]
        }, 
        response() {
            return this.$store.getters["questions/parseValueToString"](this.id, this.value)
        }
    }
}
</script>