<template>
	<div>
		<template v-if="appointmentParticipant">
			<dashboard-chip
				:key="i"
				v-for="(v, i) in chips"
				:i="i"
				:value="v"
			></dashboard-chip>
		</template>
	</div>
</template>
<script>
import DashboardChip from "@c/dashboard/DashboardChip.vue";
export default {
	name: "AppointmentInfoChips",
	props: {
		value: { type: String },
	},
	computed: {
		appointmentParticipant() {
			return this.$store.state.appointmentParticipants.data[this.value];
		},
		appointment() {
			return this.$store.state.appointments.data[
				this.appointmentParticipant.appointment
			];
		},
		chips() {
			let chips = [];
			if (this.appointmentParticipant.appointment_status) {
				chips.push(
					this.mwutils.prettyPrint(
						this.appointmentParticipant.appointment_status,
						"TITLE"
					)
				);
			}
			if (this.appointmentParticipant.quota) {
				chips.push(this.appointmentParticipant.quota);
			}
			return chips;
		},
	},
	components: {
		DashboardChip,
	},
};
</script>

DashboardChip
