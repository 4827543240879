<template>
	<v-sheet color="transparent" class="pa-2">
		<template v-if="haveProjects">
			<h3 class="font-weight-light mb-5">
				My Current Projects
			</h3>

				<project-tile
					v-for="project in trackedProjects"
					:key="project"
					:value="project"
				>
				</project-tile>
		</template>
		<template v-else>
			<p class="font-weight-light">
				Not tracking any projects. 
			</p>
			<p class="text-right">
				<v-btn color="primary" router-link to="/projects">Go to Projects</v-btn>
			</p>
		</template>
	</v-sheet>
</template>

<script>
import ProjectTile from "@c/dashboard/ProjectTile"
export default {
	name: "CurrentProjects",
	computed: {
		auth() {
			return this.$store.state.auth.data.id;
		},
        trackedProjects(){
            return this.$store.getters["auth/trackedProjects"];
        }, 
		haveProjects() {
			return this.trackedProjects.length > 0;
		},
	},
	components: {
		ProjectTile
	},
    watch: {
        trackedProjects: {
            immediate: true, 
            handler(ids) {
                if( ids.length ){
                    this.$store.dispatch("projects/fetchByIds", ids )
                }
            }
        }
    }
};
</script>
