<template>
	<v-sheet v-if="loggedIn">
		<current-appointments></current-appointments>
		<current-surveys></current-surveys>
		<current-projects></current-projects>
	</v-sheet>
</template>

<script>
import CurrentAppointments from "@c/dashboard/CurrentAppointments";
import CurrentProjects from "@c/dashboard/CurrentProjects";
import CurrentSurveys from "@c/dashboard/CurrentSurveys";
export default {
	name: "Home",
	computed: {
		loggedIn() {
			return this.$store.state.auth.data.id ? true : false;
		},
	},
	components: {
		CurrentAppointments,
		CurrentProjects,
		CurrentSurveys
	},
};
</script>
