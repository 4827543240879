<template>
	<mw-dialog
		v-model="dialog"
		small
		@submit="sendMessage"
		submit-btn
		cancel-btn
		v-if="valid"
	>
		<template v-slot:button>
			<v-btn small block color="primary" @click="dialog = true"
				>Message</v-btn
			>
		</template>
		<template v-slot:dialog-body>
			This will send an sms to the participant
			<v-textarea v-model="body" label="Message"> </v-textarea>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "SendMessageToParticipant",
	props: {
		id: { type: String },
		appointment: { type: String },
	},
	data: () => {
		return {
			dialog: false,
			body: "",
		};
	},
	computed: {
		isMultiple() {
			return this.appointmentParticipants.length > 1;
		},
		user() {
			return this.$store.state.users.data[this.id];
		},
		valid() {
			return this.user.phone.length > 0;
		},
	},
	components: {
		MwDialog,
	},
	methods: {
		sendMessage() {
			const self = this;
			let message = {
				values: {
					user: self.id,
					appointment: self.appointment,
				},
				body: self.body,
			};
			self.$store.dispatch("messages/sendOut", message).then(() => {
				self.body = "";
				self.dialog = false;
				self.$root.$emit("showToast", "Sent");
			}).catch( () => {
				self.$root.$emit("showToast", "Error sending", true );
			});
		},
	},
};
</script>
