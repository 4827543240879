<template>
	<mw-dialog v-model="dialog" submit-btn cancel-btn @submit="submit">
		<template v-slot:button>
			<v-btn block small color="primary" @click="dialog = true">
				{{ !isTracked ? "Track" : "Untrack" }} {{ object }}
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<div>
				This
				{{
					!isTracked
						? `adds the ${object} to`
						: `removes the ${object} from`
				}}
				your tracked list and dashboard
			</div>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import { arrayUnion, arrayRemove } from "vuex-easy-firestore";
export default {
	name: "TrackSurvey",
	props: {
		id: { type: String },
		object: { type: String, default: "survey" },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		isTracked() {
			return this.auth.settings.track[`${this.object}s`].includes(this.id);
		},
	},
	components: {
		MwDialog,
	},
	methods: {
		submit() {
			if (!this.isTracked) {
				this.$store.dispatch("auth/patch", {
					settings: {
						track: { [`${this.object}s`]: arrayUnion(this.id) },
					},
				});
			} else {
				this.$store.dispatch("auth/patch", {
					settings: {
						track: { [`${this.object}s`]: arrayRemove(this.id) },
					},
				});
			}
		},
	},
};
</script>
