<template>
	<v-card class="mb-2 mr-2" flat outlined tile v-if="project">
		<v-card-title>
			<span class="font-weight-light">
				{{ project.client_name }}
			</span>
			<v-spacer></v-spacer>
			<project-dates :value="value"></project-dates>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="4">
					<dashboard-chip
						:value="mwutils.prettyPrint(project.project_status, 'TITLE')"
					></dashboard-chip>
					<p class="mt-12">
						<v-btn
							class="mr-2"
							router-link
							color="success"
							dark
							:to="`/projects/${value}`"
							>View Project</v-btn
						>
						<v-btn
						rounded
						text
						outlined
						small
						v-for="client in clients"
						class="mr-2 pa-5"
						:key="client.id"
						target="blank"
						:href="`mailto:${client.email}`"
					>
						<v-icon>mdi-email</v-icon> {{ client.name }}
					</v-btn>
					</p>
				</v-col>
				<v-col cols="8" class="text-right">
					<dashboard-avatar
						:value="scheduled"
						text="Recruited"
						:i="1"
					></dashboard-avatar>
					<dashboard-avatar
						:value="confirmed"
						text="Confirmed"
						:i="2"
					></dashboard-avatar>
					<dashboard-avatar
						:value="complete"
						text="Complete"
						:i="3"
					></dashboard-avatar>
					<p>
						<dashboard-chip
							v-for="(quota, i) in quotas"
							:key="i"
							:i="i"
							:value="quota.join(':  ')"
						></dashboard-chip>
					</p>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<track-button :id="value" object="project"></track-button>
		</v-card-actions>
	</v-card>
</template>
<style>
.inline-tile {
	margin-right: 10px;
	display: inline-block;
}
</style>

<script>
import DashboardChip from "@c/dashboard/DashboardChip.vue";
import DashboardAvatar from "@c/dashboard/DashboardAvatar.vue";
import TrackButton from "@c/buttons/TrackButton";
import ProjectDates from "@c/dashboard/ProjectDates.vue";
export default {
	name: "ProjectTile",
	props: {
		value: { type: String },
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.value];
		},
		clients() {
			const self = this;
			return self.project.clients
				.map((id) => {
					return self.$store.state.users.data[id];
				})
				.filter((a) => a);
		},
		appointments() {
			return this.$store.getters.getByKey(
				"appointments",
				"project",
				this.value
			);
		},
		appointmentParticipants() {
			let appointments = this.appointments.map((a) => a.id);
			return this.$store.getters.getByKeyIn(
				"appointmentParticipants",
				"appointment",
				appointments
			);
		},
		scheduled() {
			return this.appointmentParticipants.filter( 
				(a) => a.appointment_status == "recruited" || a.appointment_status == "provisional"
			).length;
		},
		confirmed() {
			return this.appointmentParticipants.filter(
				(a) => a.appointment_status == "confirmed"
			).length;
		},
		complete() {
			return this.appointmentParticipants.filter(
				(a) => "complete" == a.appointment_status
			).length;
		},
		quotas() {
			const self = this;
			if (!this.project.quotas) {
				return [];
			}
			return this.project.quotas.map((q) => [
				q,
				self.appointmentParticipants.filter((a) => a.quota == q).length,
			]);
		},
	},
	components: { DashboardChip, DashboardAvatar, ProjectDates, TrackButton },
	created() {
		this.$store.dispatch("appointments/fetchByKey", {
			key: "project",
			value: this.value,
		});
	},
};
</script>
