<template>
	<v-sheet color="transparent" class="pa-2 mb-7">
        <template v-if="trackedSurveys.length">
            <h3 class="font-weight-light mb-5">
				My Tracked Surveys

			</h3>
            <survey-tile v-for="survey in trackedSurveys" :id="survey" :key="survey">

            </survey-tile>
        </template>
        <template v-else>
            
			<p class="font-weight-light">
				Not tracking any surveys. 
			</p>
			<p class="text-right">
				<v-btn color="primary" router-link to="/surveys">Go to surveys</v-btn>
			</p>
        </template>
    </v-sheet>
</template>

<script>
import SurveyTile from "@c/dashboard/SurveyTile";
export default {
    name: "CurrentSurveys",
    computed: {
        trackedSurveys(){
            return this.$store.state.auth.data.settings.track.surveys;
        }, 
        surveys(){
            const self = this;
            return self.trackedSurveys.map( id => self.$store.state.surveys.data[id] );
        }
    },
    components: {
        SurveyTile
    },
    watch: {
        trackedSurveys: {
            immediate: true, 
            handler(ids) {
                if( ids.length ){
                    this.$store.dispatch("surveys/fetchByIds", ids )
                }
            }
        }
    }

}
</script>