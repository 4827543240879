<template>
	<div class="text-center mr-2 tile inline-tile">
		<v-avatar tile :color="color" class="mx-auto">
			<span class="white--text"> {{ value }} </span>
		</v-avatar>
        <span class="overline small"><br/>{{text}}</span>
	</div>
</template>

<style lang="less">
    .overline.small {
        font-size: 0.6em !important;
    }
</style>

<script>
export default {
	name: "DashboardAvatar",
	props: {
		text: { type: String },
		value: { type: [String, Number] },
		i: { type: Number, default: 0 },
	},
	computed: {
		color() {
			return this.$store.state.colors[this.i];
		},
	},
};
</script>
