<template>
	<mw-dialog v-model="dialog" cancel-btn v-if="user">
		<template v-slot:button>
			<v-btn small rounded color="secondary" dark @click="dialog = true">
				{{name}}
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<v-btn color="success" class="mb-2" :href="`/users/${appointmentParticipant.user}`" target="_blank">View User</v-btn>
			<p>
				Last login:
				{{ mwtimeutils.formatAsDateTimeString(user.updated_at) }}
			</p>
			<p>
				<v-btn v-if="user.email" rounded :href="`mailto:${user.email}`">{{
					user.email
				}}</v-btn>
			</p>
			<p>
				<v-btn v-if="user.phone" rounded :href="`tel:${user.phone}`">{{
					user.phone
				}}</v-btn>
			</p>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "ParticipantInfo",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		appointmentParticipant() {
			return this.$store.state.appointmentParticipants.data[this.value];
		},
		user() {
			return this.$store.state.users.data[this.appointmentParticipant.user];
		},
		name(){
			return this.user.name || this.user.first_name + " " + this.user.last_name
		}
	},
	methods: {},
	components: {
		MwDialog,
	},
};
</script>
