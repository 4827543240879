<template>
	<v-card class="mb-2 pa-2" flat outlined tile v-if="survey">
		<v-row>
			<v-col>
				{{ survey.internal_name }}
			</v-col>
			<v-col cols="12" sm="4">
				Total: {{ responses.length }}<br />
				Today: {{ today }}<br />
				Yesterday: {{ yesterday }}
			</v-col>
			<v-col> Latest: {{ lastResponseDate }} </v-col>
			<v-col>
				<v-btn
					small
					block
					router-link
					:to="`responses/${id}`"
					color="success"
					dark
					>View Responses</v-btn
				>
			</v-col>
			<v-col>
				<track-button :id="id" object="survey"></track-button>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import TrackButton from "@c/buttons/TrackButton";
export default {
	name: "SurveyTile",
	props: {
		id: { type: String },
	},
	computed: {
		survey() {
			return this.$store.state.surveys.data[this.id];
		},
		responses() {
			return this.$store.getters.getByKey(
				"surveyResponses",
				"survey",
				this.id
			);
		},
		today(){
			const self = this;
			let now = self.mwtimeutils.parseDateToMoment(new Date().setHours(0,0,0,0));
			return self.responses.filter( response => {
				let time = self.mwtimeutils.parseDateToMoment(response.created_at );
				return now < time;
			} ).length;
		}, 
		yesterday() {
			const self = this;
			let today = self.mwtimeutils.parseDateToMoment(new Date().setHours(0,0,0,0));
			let yesterday = today.clone().subtract(1, "day");
			return self.responses.filter( response => {
				let time = self.mwtimeutils.parseDateToMoment(response.created_at );
				return time > yesterday && time < today;
			} ).length;
		},
		lastResponseDate() {
			let sorted = this.mwutils.sortByDate(this.responses, "created_at");
			if (sorted.length) {
				return this.mwtimeutils.formatAsDateTimeString(
					sorted[0].created_at
				);
			}
			return "Never";
		},
	},
	components: {
		TrackButton,
	},
	created() {
		this.$store.dispatch("surveys/fetchById", this.id);
		this.$store.dispatch("surveyResponses/fetchBySurvey", this.id);
	},
};
</script>
