<template>
	<mw-dialog large v-model="dialog" cancel-btn>
		<template v-slot:button>
			<v-btn x-small color="primary" @click="dialog = true">
				View Profile
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<div v-if="survey">
				<div v-for="question in questions" :key="question">
					<p
						class="subtitle-1 font-weight-bold"
						v-html="getTitle(question)"
					></p>
					<p v-if="!question.subquestions">
						<response-string
							:id="question"
							:value="response.data[question]"
						></response-string>
					</p>
					<p v-else>
						<span
							class="subtitle-2"
							v-for="subquestion in question.subquestions" :key="subquestion.value"
						>
							{{subquestion.text}}

						</span><br/>
				
						<response-string v-if="response.data[question]"
							:id="question"
							:value="response.data[question][subquestion.value]"
						></response-string>
					</p>
				</div>
			</div>
		</template>
	</mw-dialog>
</template>

<script>
import ResponseString from "@c/responses/ResponseString";
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "ParticipantProfile",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		appointmentParticipant() {
			return this.$store.state.appointmentParticipants.data[this.value];
		},
		appointment() {
			if (!this.appointmentParticipant) {
				return;
			}
			return this.$store.state.appointments.data[
				this.appointmentParticipant.appointment
			];
		},
		response() {
			if (!this.appointment) {
				return;
			}
			return this.$store.state.surveyResponses.data[
				this.appointmentParticipant.response
			];
		},
		survey() {
			if (!this.response) {
				return;
			}
			return this.$store.state.surveys.data[this.response.survey];
		},
		questions() {
			return this.survey.questions
		},
	},
	components: {
		MwDialog,
		ResponseString,
	},
	watch: {
		response(value) {
			if (value.survey && !this.survey) {
				this.$store.dispatch("surveys/fetchById", value.survey);
			}
		},
	},
	methods: {
		getTitle(id){
			return this.$store.getters['questions/title'](id)
		}
	}
};
</script>
