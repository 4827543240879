<template>
	<div v-if="appointment">
		<v-card
			width="100%"
			outlined
			flat
			tile
			class="pa-3 mb-2 font-weight-light"
		>
			<v-row>
				<v-col cols="12" sm="6" md="1">
					{{
						mwtimeutils
							.parseDateToMoment(appointment.start)
							.format("h:mma")
					}}
					<v-btn small color="primary" v-if="appointment.methodology == 'video'" @click="focus = appointment.id">Go to Session</v-btn>
				</v-col>
				<v-col cols="12" sm="6" md="2">
					<b v-if="project">{{ project.internal_name }}</b>
				</v-col>
				<v-col cols="12" md="9">
					<v-row v-for="participant in appointmentParticipants" :key="participant.id">
						<template v-if="users[participant.user]">
							<v-col cols="12" sm="3">
								<participant-contact-details
									:value="participant.id"
								></participant-contact-details
								><br />
									
								
							</v-col>
							<v-col cols="12" sm="3">
								<appointment-info-chips
									:value="participant.id"
								></appointment-info-chips>
							</v-col>
							<v-col cols="12" sm="2">
								<chase-button v-if="isSoon" :value="participant.id"></chase-button>
								<chase-or-check-consent
									v-else
									:value="participant.id"
								></chase-or-check-consent>
							</v-col>
							<v-col cols="12" sm="2">
								<send-message-to-participant
									:id="participant.user"
									:appointment="value"
								></send-message-to-participant>
							</v-col>
							<v-col cols="12" sm="2">
								<participant-profile
									:value="participant.id"
								></participant-profile>
							</v-col>
						</template>
					</v-row>
				</v-col>
			</v-row>
		</v-card>
		<click-appointment
			:value="focus"
			@close="focus = null"
		></click-appointment>
	</div>
</template>

<style lang="less">
.large-text {
	font-size: 1.4em;
}
</style>
<script>
import ParticipantProfile from "@c/dashboard/ParticipantProfile";
import AppointmentInfoChips from "@c/dashboard/AppointmentInfoChips";
import ChaseButton from "@c/dashboard/ChaseButton";
import ChaseOrCheckConsent from "@c/consent/ChaseOrCheckConsent.vue";
import SendMessageToParticipant from "@c/communication/SendMessageToParticipant.vue";
import ParticipantContactDetails from "@c/dashboard/ParticipantContactDetails.vue";
import ClickAppointment from "@c/dashboard/ClickAppointment.vue";
export default {
	name: "AppointmentTile",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			focus: null
		}
	},
	computed: {
		isSoon() {
			let soon = this.mwtimeutils.parseDateToMoment(new Date());
			soon = soon.add(10, "minutes");

			let start = this.mwtimeutils.parseDateToMoment(this.appointment.start);
			return start < soon;
		},
		appointment() {
			return this.$store.state.appointments.data[this.value];
		},
		project() {
			return this.$store.state.projects.data[this.appointment.project];
		},
		appointmentParticipants() {
			return this.$store.getters.getByKey(
				"appointmentParticipants",
				"appointment",
				this.value
			);
		},
		users() {
			return this.$store.state.users.data;
		},
		appointmentParticipantIds() {
			return this.appointmentParticipants.map((a) => a.id);
		},
		logo() {
			if (this.appointment.methodology == "phone") {
				return "mdi-phone";
			}
			if (this.appointment.methodology == "video") {
				if (this.appointment.video_medium == "google meet") {
					return "mdi-google";
				}
				if (this.appointment.video_medium == "zoom") {
					return "mdi-alpha-z-circle";
				}
				if (this.appointment.video_medium == "ms teams") {
					return "mdi-microsoft-teams";
				}
				if (this.appointment.video_medium == "skype") {
					return "mdi-skype";
				}
				return "mdi-message-video";
			}
			return "";
		},
	},
	components: {
		AppointmentInfoChips,
		SendMessageToParticipant,
		ParticipantProfile,
		ChaseOrCheckConsent,
		ChaseButton,
		ParticipantContactDetails,
		ClickAppointment,
	},
};
</script>
